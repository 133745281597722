import { Injectable } from '@angular/core';

import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../../environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { eCountPendenciasEmpresa, eWsStatus, WS_EVENTS } from '../enums/Enums';
import {
  IEventMessageEntity,
  ILoginDataConfirmation,
  IQtdConnections,
  IUserLoginData,
} from '../models/WS.data.model';
import { AuthenticationService } from './authentication.service';
import { AccountService } from './account.service';
import { UserService } from './user.service';
import { AuthorizationService } from './authorization.service';
import { Empresa } from '../models/Account.data.model';
import { Cliente } from '../models/Client.data.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  socket?: Socket;

  url = environment.SERVER_NAME;
  port = environment.SOCKET_IO_CONFIG.port;
  namespace = environment.SOCKET_IO_CONFIG.namespace;

  private _ws_connected = new BehaviorSubject<boolean>(false);

  private _ws_connected_v2 = new BehaviorSubject<eWsStatus | undefined>(
    undefined
  );

  private _ws_client_id = new BehaviorSubject<string | undefined>(undefined);
  private _ws_qtd_connections = new BehaviorSubject<IQtdConnections>({
    qtd_connected: 0,
    qtd_logged: 0,
    qtd_customer_logged: 0,
  });
  private _ws_qtd_pendencias_admin =
    new BehaviorSubject<eCountPendenciasEmpresa>({
      qtdEmpresasAguardandoAprovacao: 0,
      qtdUsuariosAguardandoAprovacao: 0,
    });
  private _ws_qtd_pendencias_manager =
    new BehaviorSubject<eCountPendenciasEmpresa>({
      qtdEmpresasAguardandoAprovacao: 0,
      qtdUsuariosAguardandoAprovacao: 0,
    });
  private _loginData = new BehaviorSubject<IUserLoginData | undefined>(
    undefined
  );
  private _loginDataConfirmation = new BehaviorSubject<
    ILoginDataConfirmation | undefined
  >(undefined);
  private _replyData = new BehaviorSubject<any | undefined>(undefined);
  private _loggedUserList = new BehaviorSubject<IUserLoginData[] | undefined>(
    undefined
  );
  private _messageFromServer = new BehaviorSubject<
    IEventMessageEntity | undefined
  >(undefined);
  private _broadcastMessageFromServer = new BehaviorSubject<
    IEventMessageEntity | undefined
  >(undefined);

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly auhorizationService: AuthorizationService,
    private readonly accountService: AccountService,
    private readonly userService: UserService
  ) {
    // this._ws_connected.next(false);
    // this._ws_connected_v2.next('DISCONNECTED');

    // console.log(
    //   'WS_Socket - connectionStatus - constructor:',
    //   this._ws_connected.value,
    //   this._ws_connected_v2.value
    // );
  }

  // -----
  getWSConnected() {
    return this._ws_connected.value;
  }

  isWSConnectedObserver() {
    return this._ws_connected.asObservable();
  }
  // -----

  
  getWSConnectedV2(): eWsStatus|undefined {
    return this._ws_connected_v2.value;
  }

  isWSConnectedV2Observer(): Observable<eWsStatus | undefined> {
    return this._ws_connected_v2.asObservable();
  }
  // -----

  // -----
  getCurrentWSClientId() {
    return this._ws_client_id.value;
  }

  wsClientIdObserver() {
    return this._ws_client_id.asObservable();
  }
  // -----

  // -----
  getWSQtdConnections() {
    return this._ws_qtd_connections.value;
  }

  isWSQtdConnectionsObserver() {
    return this._ws_qtd_connections.asObservable();
  }
  // -----

  // -----
  getCurrentWSLoginData() {
    return this._loginData.value;
  }

  wsLoginDataObserver() {
    return this._loginData.asObservable();
  }
  // -----

  // -----
  getCurrentWSLoginDataConfirmation() {
    return this._loginDataConfirmation.value;
  }

  wsLoginDataConfirmationObserver() {
    return this._loginDataConfirmation.asObservable();
  }
  // -----

  // -----
  getCurrentWSReplyData() {
    return this._replyData.value;
  }

  wsReplyDataObserver() {
    return this._replyData.asObservable();
  }
  // -----

  // -----
  getCurrentWSUsuariosLogados() {
    return this._loggedUserList.value;
  }

  wsUsuariosLogadosObserver() {
    return this._loggedUserList.asObservable();
  }
  // -----

  // -----
  getCurrentWSBroadcastMessageFromServer() {
    return this._broadcastMessageFromServer.value;
  }

  wsBroadcastMessageFromServerObserver() {
    return this._broadcastMessageFromServer.asObservable();
  }
  // -----

  // -----
  getCurrentWSMessageFromServer() {
    return this._messageFromServer.value;
  }

  wsMessageFromServerObserver() {
    return this._messageFromServer.asObservable();
  }
  // -----

  // -----
  getWSQTDPendenciasAdmin() {
    return this._ws_qtd_pendencias_admin.value;
  }

  wsQTDPendenciasAdminObserver() {
    return this._ws_qtd_pendencias_admin.asObservable();
  }
  // -----

  // -----
  getWSQTDPendenciasManager() {
    return this._ws_qtd_pendencias_manager.value;
  }

  wsQTDPendenciasManagerObserver() {
    return this._ws_qtd_pendencias_manager.asObservable();
  }
  // -----

  connect() {
    // debugger;

    console.log('WS_Socket -> WS_Service - socket:', this.socket);
    // console.log(
    //   'WS_Socket -> WS_Service - socket - namespace:',
    //   this.socket?.of(environment.SOCKET_IO_CONFIG.namespace)
    // );

    const url_topzap = `http://localhost:${this.port}`;
    const url_server = this.port
      ? `${this.url}:${this.port}/${this.namespace}`
      : `${this.url}/${this.namespace}`;

    console.log('WS_Service - connect', url_server);
    const token =
      this.authenticationService.getTokenFomLocalHistory().accessToken;
    console.log('WS_Socket - -> WS_Service - token:', token);

    if (token) {
      const config: SocketIoConfig = {
        url: url_server,
        options: {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
        },
      };
      console.log('WS_Socket -> WS_Service - config:', config);

      // debugger;

      // if (this._ws_connected.value) {
      //   this.disconnect();
      //   this.socket = undefined;
      // }

      if (!this.socket) {
        this.socket = new Socket(config);
        this.socket?.connect();
      }

      if (this.socket) {
        this.socket.on(WS_EVENTS.connect, () => {
          // this.connectionStatus = true;

          this._ws_connected_v2.next('CONNECTED');
          // this._ws_connected.next(true);

          console.log('WS_Socket - Conectado ao servidor');
          console.log(
            'WS_Socket - Conectado ao servidor -> quantidades:',
            this._ws_qtd_connections.value
          );
          this.resendUserLogin();

          if(this.accountService.currentEmpresaLogada && this.auhorizationService.currentCustomerLogged) {
            this.resendCustomerLogin(this.accountService.currentEmpresaLogada, this.auhorizationService.currentCustomerLogged);
          }
        });

        this.socket.on(WS_EVENTS.disconnect, (reason: any) => {
          console.log('WS_Socket - Desconectado do servidor:', reason);

          // this._ws_connected.next(false);
          this._ws_connected_v2.next('DISCONNECTED');
          // this.connectionStatus = false;

          this._ws_qtd_connections.next({ qtd_connected: 0, qtd_logged: 0 });
        });

        this.socket.on(WS_EVENTS.reconnect, () => {
          console.log('WS_Socket - Reconectado ao servidor');
        });

        this.socket.on(WS_EVENTS.error, (error: any) => {
          console.error('WS_Socket - Erro na conexão:', error);
        });

        this.socket.on(WS_EVENTS.connected_reply, (data: any) => {
          if (data) {
            console.log('WS_Socket - meu Client Id:', data);
            this._ws_client_id.next(data);

            // se recebi meu client id, então estou conectado
            this._ws_connected_v2.next('CONNECTED');
            // this._ws_connected.next(true);
            // this.connectionStatus = true;
          }
        });

        this.socket.on(
          WS_EVENTS.login_reply,
          (data: ILoginDataConfirmation) => {
            if (data) {
              console.log('WS_Socket - login_reply data:', data);
              this._loginDataConfirmation.next(data);

              // se recebi a confirmação do login, então estou conectado
              this._ws_connected_v2.next('CONNECTED');
              // this._ws_connected.next(true);
              // this.connectionStatus = true;
            }
          }
        );

        this.socket.on(
          WS_EVENTS.broadcast_from_server,
          (data: IEventMessageEntity) => {
            console.log('WS_Socket - broadcast message from server:', data);
            this._broadcastMessageFromServer.next(data);
          }
        );

        this.socket.on(
          WS_EVENTS.message_from_server,
          (data: IEventMessageEntity) => {
            console.log('WS_Socket - message from server:', data);
            this._messageFromServer.next(data);
          }
        );

        this.socket.on(
          WS_EVENTS.logged_list_reply,
          (data: IUserLoginData[]) => {
            console.log('WS_Socket - usuarios_logados:', data);
            this._loggedUserList.next(data);
          }
        );

        this.socket.on(
          WS_EVENTS.connected_qtd_reply,
          (data: IQtdConnections) => {
            console.log('WS_Socket - qtd usuarios_conectados:', data);
            this._ws_qtd_connections.next(data);
          }
        );

        this.socket.on(
          WS_EVENTS.pendencias_admin_reply,
          (data: eCountPendenciasEmpresa) => {
            console.log('WS_Socket - Qtd. pendências para ADMIN:', data);
            this._ws_qtd_pendencias_admin.next(data);
          }
        );

        this.socket.on(
          WS_EVENTS.pendencias_manager_reply,
          (data: eCountPendenciasEmpresa) => {
            console.log('WS_Socket - Qtd. pendências para MANAGER:', data);
            this._ws_qtd_pendencias_manager.next(data);
          }
        );
      }

      return true;
    }
    return false;
  }

  disconnect() {
    try {
      // debugger;

      console.log('WS_Socket -> WS_Service - disconnect');
      if (this.socket) this.socket.disconnect();
      this.socket = undefined;

      // this._ws_connected.next(false);
      this._ws_connected_v2.next('DISCONNECTED');
      // this.connectionStatus = false;
    } catch (error) {
      console.error('WS_Socket -> WS_Service - disconnect ERROR:', error);
    }
  }

  performEventAction(evento: WS_EVENTS, message: any) {
    try {
      console.log(
        'WS_Socket -> WS_Service - sendMessage:',
        evento,
        message,
        this.socket
      );
      if (this.socket) this.socket.emit(evento, message);
    } catch (error) {
      console.error('WS_Socket -> WS_Service - sendMessage ERROR:', error);
    }
  }

  performUserLogin(login: IUserLoginData) {
    try {
      console.log(
        'WS_Socket -> WS_Service - performUserLogin:',
        login,
        this.socket
      );
      if (this.socket) this.socket.emit(WS_EVENTS.login_event, login);
    } catch (error) {
      console.error('WS_Socket -> WS_Service - performUserLogin ERROR:', error);
    }
  }

  resendUserLogin() {
    // se tem dados de login, então envia novamente
    const _geoData = this.authenticationService.getGeolocationData();
    const token =
      this.authenticationService.getTokenFomLocalHistory().accessToken;

    if (
      this.accountService.currentEmpresaLogada?.empresa_id &&
      this.userService.currentUser.userid &&
      _geoData &&
      _geoData.ip &&
      token
    ) {
      const { empresa_id } = this.accountService.currentEmpresaLogada;
      const { userid, user_role, username } = this.userService.currentUser;

      this.performUserLogin({
        action: 'login',
        userid,
        username,
        user_role,
        empresa_logada: empresa_id,
        ip_address: _geoData.ip,
        token,
      });
    }
  }

  resendCustomerLogin(currentEmpresa: Empresa, currenteCliente: Cliente) {
    // debugger;

    // se tem dados de login, então envia novamente
    const _geoData = this.authenticationService.getGeolocationData();
    const token =
      this.authenticationService.getTokenFomLocalHistory().accessToken;

    if (
      currentEmpresa?.empresa_id &&
      currenteCliente?.cliente_id &&
      _geoData &&
      _geoData.ip &&
      token
    ) {
      const { empresa_id } = currentEmpresa;
      const { cliente_id, nome, cpf } = currenteCliente;

      this.performUserLogin({
        action: 'login',
        userid: cliente_id.toString(),
        username: nome,
        cpf_cliente: cpf,
        user_role: 'CUSTOMER',
        empresa_logada: empresa_id,
        ip_address: _geoData.ip,
        token,
      });
    }
  }

  performWSUserLogoff() {
    try {
      if (this._loginData.value?.userid) {
        console.log(
          'WS_Socket -> WS_Service - performUserLogoff:',
          this._loginData.value.userid,
          this.socket
        );
        if (this.socket)
          this.socket.emit(
            WS_EVENTS.logoff_event,
            this._loginData.value.userid
          );
      }
    } catch (error) {
      console.error(
        'WS_Socket -> WS_Service - performUserLogoff ERROR:',
        error
      );
    }
  }

  performWSCustomerLogoff(logado?: ILoginDataConfirmation) {
    // debugger;
    try {
      if (logado && logado?.userid) {
        console.log(
          'WS_Socket -> WS_Service - performCustomerLogoff:',
          logado,
          this.socket
        );
        if (this.socket)
          this.socket.emit(
            WS_EVENTS.logoff_event,
            logado.userid,
          );
      }
    } catch (error) {
      console.error(
        'WS_Socket -> WS_Service - performCustomerLogoff ERROR:',
        error
      );
    }
  }

  getMessage() {
    if (this.socket) {
      return this.socket
        .fromEvent('reply')
        .pipe(
          map((data: any) =>
            console.log('WS_Socket -> WS_Service - mesagem do servidor:', data)
          )
        );
    }
    return null;
  }
}
